<template>
  <b-card>
    <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
      <b-form @submit.prevent="handleSubmit(onSubmit)" enctype="multipart/form-data">
        <b-row>

          <b-col cols="4" md="4" lg="4">
            <validation-provider #default="validationContext" name="Slider Group">
              <b-form-group label="Slider Group" :state="getValidationState(validationContext)">
                <v-select v-model="dataInfo.slider_group" :options="sliderGroupOptions" :reduce="val => val.value" :clearable="false"/>
                <b-form-invalid-feedback :state="getValidationState(validationContext)">
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>

          <b-col cols="4" md="4" lg="4">
            <validation-provider #default="validationContext" name="Slider Type">
              <b-form-group label="Slider Type" :state="getValidationState(validationContext)">
                <v-select v-model="dataInfo.slider_type" :options="sliderTypeOptions" :reduce="val => val.value" :clearable="false"/>
                <b-form-invalid-feedback :state="getValidationState(validationContext)">
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>

          <b-col cols="4" md="4" lg="4">
            <validation-provider #default="validationContext" name="Sort Number">
              <b-form-group label="Sort Number">
                <b-form-input trim placeholder="Sort Number" v-model="dataInfo.order_number" :state="getValidationState(validationContext)"/>
                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>

        </b-row>

        <b-tabs pills align="right">
          <b-tab v-for="language in dataInfo.languages" :key="language.language_id" @click="getUrlTypeValues(language.link_type)">
            <template #title>
              <span>{{ language.language_name }}</span>
            </template>
            <b-row class="mt-1">

              <b-col cols="12" md="12" lg="12">
                <validation-provider #default="validationContext" name="Title">
                  <b-form-group :label="'Title (' + language.language_name + ')'">
                    <b-form-input trim placeholder="Title" v-model="language.title" :state="getValidationState(validationContext)"/>
                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>

              <b-col cols="12" md="12" lg="12">
                <validation-provider #default="validationContext" name="Description">
                  <b-form-group :label="'Description (' + language.language_name + ')'">
                    <b-form-input trim placeholder="Description" v-model="language.description" :state="getValidationState(validationContext)"/>
                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>

              <b-col cols="6" md="6" lg="6">
                <validation-provider #default="validationContext" name="Url Type">
                  <b-form-group :label="'Url Type (' + language.language_name + ')'">
                    <v-select class="url-type" v-model="language.link_type" :options="linkTypeOptions" :reduce="val => val.value" v-on:input="getUrlTypeValues"/>
                    <b-form-invalid-feedback :state="getValidationState(validationContext)">
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>

              <b-col cols="6" md="6" lg="6">
                <validation-provider #default="validationContext" name="Url">
                  <b-form-group :label="'Url (' + language.language_name + ')'">
                    <v-select v-model="language.link" :options="urlOptions" :reduce="val => val.value" v-if="language.link_type!==4"/>
                    <b-form-input trim placeholder="Url" v-model="language.link" :state="getValidationState(validationContext)" v-if="language.link_type===4"/>
                    <b-form-invalid-feedback :state="getValidationState(validationContext)">
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>

              <b-col cols="6" md="6" lg="6">
                <b-form-group :label="'Image (' + language.language_name + ')'">
                  <b-media no-body>
                    <b-media-aside>
                      <b-img rounded :src="(language.imageBase64 ? language.imageBase64 : getApiFile(language.image_url, true))" height="80"/>
                    </b-media-aside>

                    <b-media-body class="mt-75 ml-75">
                      <b-button variant="primary" size="sm" class="mb-75 mr-75" @click="selectFile('image_file_'+language.language_id)">Upload</b-button>
                      <b-form-file
                          plain
                          :id="'image_file_'+language.language_id"
                          :name="'image_file_'+language.language_id"
                          :hidden="true"
                          accept=".jpg, .jpeg, .png, .gif, .JPG, .JPG"
                          @input="inputImageRenderer('image_file_'+language.language_id, language.language_id)"/>
                      <b-button variant="outline-secondary" size="sm" class="mb-75 mr-75" @click="resetFile('image_file_'+language.language_id, language.language_id)">Reset</b-button>
                      <b-card-text>Allowed JPG, GIF or PNG.</b-card-text>
                    </b-media-body>
                  </b-media>
                </b-form-group>
              </b-col>

              <b-col cols="6" md="6" lg="6">
                <b-form-group :label="'Mobile Image (' + language.language_name + ')'">
                  <b-media no-body>
                    <b-media-aside>
                      <b-img rounded :src="(language.mobileImageBase64 ? language.mobileImageBase64 : getApiFile(language.mobile_image_url, true))" height="80"/>
                    </b-media-aside>

                    <b-media-body class="mt-75 ml-75">
                      <b-button variant="primary" size="sm" class="mb-75 mr-75" @click="selectFile('mobile_image_file_'+language.language_id)">Upload</b-button>
                      <b-form-file
                          plain
                          :id="'mobile_image_file_'+language.language_id"
                          :name="'mobile_image_file_'+language.language_id"
                          :hidden="true"
                          accept=".jpg, .jpeg, .png, .gif, .JPG"
                          @input="inputImageRenderer('mobile_image_file_'+language.language_id, language.language_id)"/>
                      <b-button variant="outline-secondary" size="sm" class="mb-75 mr-75" @click="resetFile('mobile_image_file_'+language.language_id, language.language_id)">Reset</b-button>
                      <b-card-text>Allowed JPG, GIF or PNG.</b-card-text>
                    </b-media-body>
                  </b-media>
                </b-form-group>
              </b-col>

              <b-col cols="6" md="6" lg="6">
                <b-form-group :label="'Poster Image (' + language.language_name + ')'">
                  <b-media no-body>
                    <b-media-aside>
                      <b-img rounded :src="(language.posterImageBase64 ? language.posterImageBase64 : getApiFile(language.poster_image_url, true))" height="80"/>
                    </b-media-aside>

                    <b-media-body class="mt-75 ml-75">
                      <b-button variant="primary" size="sm" class="mb-75 mr-75" @click="selectFile('poster_image_file_'+language.language_id)">Upload</b-button>
                      <b-form-file
                          plain
                          :id="'poster_image_file_'+language.language_id"
                          :name="'poster_image_file_'+language.language_id"
                          :hidden="true"
                          accept=".jpg, .jpeg, .png, .gif, .JPG"
                          @input="inputImageRenderer('poster_image_file_'+language.language_id, language.language_id)"/>
                      <b-button variant="outline-secondary" size="sm" class="mb-75 mr-75" @click="resetFile('poster_image_file_'+language.language_id, language.language_id)">Reset</b-button>
                      <b-card-text>Allowed JPG, GIF or PNG.</b-card-text>
                    </b-media-body>
                  </b-media>
                </b-form-group>
              </b-col>

              <b-col cols="6" md="6" lg="6">
                <b-form-group :label="'Video File (' + language.language_name + ')'">
                  <b-media no-body>
                    <b-media-aside>
                      <b-img rounded :src="require('@/assets/images/portrait/placeholder.svg')" height="80"/>
                    </b-media-aside>

                    <b-media-body class="mt-75 ml-75">
                      <b-button variant="primary" size="sm" class="mb-75 mr-75" @click="selectFile('video_file_'+language.language_id)">Upload</b-button>
                      <b-form-file
                          plain
                          :id="'video_file_'+language.language_id"
                          :name="'video_file_'+language.language_id"
                          :hidden="true"
                          accept=".mp4, .MP4"
                          @input="inputImageRenderer('video_file_'+language.language_id, language.language_id)"/>
                      <b-button variant="outline-secondary" size="sm" class="mb-75 mr-75" @click="resetFile('video_file_'+language.language_id, language.language_id)">Reset</b-button>
                      <b-card-text>Allowed MP4 {{ (language.videoBase64 || language.video_url ? ' - File Selected' : '') }}</b-card-text>
                    </b-media-body>
                  </b-media>
                </b-form-group>
              </b-col>

            </b-row>
          </b-tab>
        </b-tabs>

        <b-row>

          <b-col cols="6" md="6" lg="6">
            <validation-provider #default="validationContext" name="Start Date" rules="required">
              <b-form-group label="Start Date" :state="getValidationState(validationContext)">
                <flat-pickr v-model="dataInfo.start_date" class="form-control"/>
                <b-form-invalid-feedback :state="getValidationState(validationContext)">
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>

          <b-col cols="6" md="6" lg="6">
            <validation-provider #default="validationContext" name="End Date" rules="required">
              <b-form-group label="End Date" :state="getValidationState(validationContext)">
                <flat-pickr v-model="dataInfo.end_date" class="form-control"/>
                <b-form-invalid-feedback :state="getValidationState(validationContext)">
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>

          <b-col cols="6" md="6" lg="6">
            <validation-provider #default="validationContext" name="Url Target" rules="required">
              <b-form-group label="Url Target" :state="getValidationState(validationContext)">
                <v-select v-model="dataInfo.link_target" :options="linkTargetOptions" :reduce="val => val.value" :clearable="false"/>
                <b-form-invalid-feedback :state="getValidationState(validationContext)">
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>

          <b-col cols="6" md="6" lg="6">
            <validation-provider #default="validationContext" name="Status" rules="required">
              <b-form-group label="Status" :state="getValidationState(validationContext)">
                <v-select v-model="dataInfo.status" :options="statusOptions" :reduce="val => val.value" :clearable="false"/>
                <b-form-invalid-feedback :state="getValidationState(validationContext)">
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>

        </b-row>

        <action-buttons :back-route="'cms-slider-list'"/>
      </b-form>
    </validation-observer>
    <Overlay :busy="busy"></Overlay>
  </b-card>
</template>

<script>
import {
  BCard, BRow, BCol, BForm, BFormGroup, BFormInput, BButton, BFormInvalidFeedback, BAvatar, BTabs, BTab, BMedia, BMediaBody, BFormFile, BMediaAside, BImg, BCardText,
} from 'bootstrap-vue'
import {ValidationProvider, ValidationObserver} from 'vee-validate'
import {required, email} from '@validations'
import vSelect from 'vue-select'
import store from "@/store"
import storeModule from "@/views/cms/slider/store"
import languageModule from "@/views/cms/language/store"
import categoryModule from "@/views/cms/category/store"
import contentModule from "@/views/cms/content/store"
import router from '@/router'
import Overlay from "@/components/Overlay.vue"
import ActionButtons from "@/components/Form/ActionButtons.vue"
import formValidation from '@core/comp-functions/forms/form-validation'
import {onUnmounted, ref} from '@vue/composition-api'
import {useToast} from 'vue-toastification/composition'
import {avatarText, getApiFile, statusOptions} from "@core/utils/filter"
import {toastMessage} from "@core/utils/utils"
import {useInputIdImageRenderer} from "@core/comp-functions/forms/form-utils"
import flatPickr from 'vue-flatpickr-component'

export default {
  components: {
    BCardText,
    BImg,
    BMediaAside,
    BFormFile,
    BMediaBody,
    BMedia,
    BTab,
    BTabs,
    BAvatar,
    BCard,
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BFormInput,
    BButton,
    BFormInvalidFeedback,

    vSelect,
    ActionButtons,
    Overlay,

    ValidationProvider,
    ValidationObserver,

    flatPickr,
  },
  data() {
    return {
      required,
      email,
    }
  },
  setup() {
    const STORE_MODULE_NAME = 'store'
    const STORE_LANGUAGE_MODULE_NAME = 'store-language'
    const STORE_CATEGORY_MODULE_NAME = 'store-category'
    const STORE_CONTENT_MODULE_NAME = 'store-content'

    if (!store.hasModule(STORE_MODULE_NAME)) {
      store.registerModule(STORE_MODULE_NAME, storeModule)
      store.registerModule(STORE_LANGUAGE_MODULE_NAME, languageModule)
      store.registerModule(STORE_CATEGORY_MODULE_NAME, categoryModule)
      store.registerModule(STORE_CONTENT_MODULE_NAME, contentModule)
    }
    onUnmounted(() => {
      if (store.hasModule(STORE_MODULE_NAME)) {
        store.unregisterModule(STORE_MODULE_NAME)
        store.unregisterModule(STORE_LANGUAGE_MODULE_NAME)
        store.unregisterModule(STORE_CATEGORY_MODULE_NAME)
        store.unregisterModule(STORE_CONTENT_MODULE_NAME)
      }
    })

    const toast = useToast()
    const busy = ref(false)
    const dataInfo = ref({
      id: 0,
      slider_group: 1,
      slider_type: 1,
      order_number: 0,
      start_date: null,
      end_date: null,
      link_target: '_parent',
      status: 1,
      languages: []
    })

    const selectFile = (input) => {
      document.getElementById(input).click()
    }
    const resetFile = (input, languageId) => {
      dataInfo.value.languages.filter(function (language) {
        if (language.language_id === languageId) {
          if (input.includes('mobile')) {
            language.mobile_image_url = null
            language.mobileImageBase64 = null
          } else if (input.includes('poster')) {
            language.poster_image_url = null
            language.posterImageBase64 = null
          } else if (input.includes('video')) {
            language.video_url = null
            language.videoBase64 = null
          } else {
            language.image_url = null
            language.imageBase64 = null
          }
        }
      })
    }
    const inputImageRenderer = (input, languageId) => {
      useInputIdImageRenderer(input, base64 => {
        dataInfo.value.languages.filter(function (language) {
          if (language.language_id === languageId) {
            if (input.includes('mobile')) {
              language.mobileImageBase64 = base64
            } else if (input.includes('poster')) {
              language.posterImageBase64 = base64
            } else if (input.includes('video')) {
              language.videoBase64 = base64
            } else {
              language.imageBase64 = base64
            }
          }
        })
      })
    }
    const onSubmit = () => {
      busy.value = true
      store.dispatch('store/' + (router.currentRoute.params.id > 0 ? 'editItem' : 'addItem'), dataInfo.value).then(response => {
        toastMessage(toast, 'success', response.data.message)
        router.push({name: 'cms-slider-list'})
      }).catch(error => {
        toastMessage(toast, 'danger', error.response.data.error_message)
      }).finally(message => {
        busy.value = false
      })
    }

    const {refFormObserver, getValidationState, resetForm} = formValidation()

    busy.value = true
    store.dispatch('store-language/fetchItems').then(languages => {

      let getData = null;
      if (router.currentRoute.params.id > 0) {
        busy.value = true
        store.dispatch('store/fetchItem', {id: router.currentRoute.params.id > 0 ? router.currentRoute.params.id : 0}).then(response => {
          getData = response.data.data
          dataInfo.value.id = getData.id;
          dataInfo.value.slider_group = getData.slider_group;
          dataInfo.value.slider_type = getData.slider_type;
          dataInfo.value.order_number = getData.order_number;
          dataInfo.value.start_date = getData.start_date;
          dataInfo.value.end_date = getData.end_date;
          dataInfo.value.link_target = getData.link_target;
          dataInfo.value.status = getData.status;

          languages.data.data.forEach((value, index) => {
            let gryLanguage = getData.language_list.filter(function (language) {
              if (language.language_id === value.id) return language;
              return null;
            });

            if (value.panel_status === 1) {
              if (gryLanguage[0].link_type === 1 || gryLanguage[0].link_type === 2 || gryLanguage[0].link_type === 3) {
                gryLanguage[0].link = parseInt(gryLanguage[0].link)
              }
              dataInfo.value.languages.push({
                language_id: value.id,
                language_name: value.name,
                title: gryLanguage[0].title,
                description: gryLanguage[0].description,
                link_type: gryLanguage[0].link_type,
                link: gryLanguage[0].link,
                image_url: gryLanguage[0].image_url,
                imageBase64: null,
                mobile_image_url: gryLanguage[0].mobile_image_url,
                mobileImageBase64: null,
                poster_image_url: gryLanguage[0].poster_image_url,
                posterImageBase64: null,
                video_url: gryLanguage[0].video_url,
                videoBase64: null,
              })
            }
          })

          getUrlTypeValues(dataInfo.value.languages[0].link_type)

        }).catch(error => {
          toastMessage(toast, 'danger', error.response.data.error_message)
          if (error.response.status === 403) {
            router.push({name: 'cms-slider-list'})
          }
        }).finally(message => {
          busy.value = false
        })
      } else {
        languages.data.data.forEach((value, index) => {
          if (value.panel_status === 1) {
            dataInfo.value.languages.push({
              language_id: value.id,
              language_name: value.name,
              title: '',
              description: '',
              link_type: 1,
              link: null,
              image_url: null,
              imageBase64: null,
              mobile_image_url: null,
              mobileImageBase64: null,
              poster_image_url: null,
              posterImageBase64: null,
              video_url: null,
              videoBase64: null,
            })
          }
        })

        getUrlTypeValues(1)
      }
    }).catch(error => {
      toastMessage(toast, 'danger', error.response.data.message)
    }).finally(message => {
      busy.value = false
    })

    const sliderGroupOptions = [
      {label: 'Main', value: 1},
    ]

    const linkTargetOptions = [
      {label: 'Parent', value: '_parent'},
      {label: 'Blank', value: '_blank'},
    ]

    const linkTypeOptions = [
      {label: 'Content Category', value: 1},
      {label: 'Content', value: 2},
      {label: 'Other', value: 3},
      {label: 'External', value: 4},
    ]

    const sliderTypeOptions = [
      {label: 'Image', value: 1},
      {label: 'Video', value: 2},
    ]

    const urlOptions = ref([])
    const getUrlTypeValues = (val) => {
      urlOptions.value = []

      if (val === 1 || val === 2) {
        busy.value = true
        store.dispatch((val === 1 ? 'store-category' : 'store-content') + '/fetchItems').then(response => {
          response.data.data.forEach((value, index) => {
            urlOptions.value.push({label: value.title, value: value.id})
          });
        }).catch(error => {
          toastMessage(toast, 'danger', error.response.data.message)
        }).finally(message => {
          busy.value = false
        })
      } else if (val === 3) {
        urlOptions.value.push({label: 'Contact', value: 1})
        urlOptions.value.push({label: 'Blog', value: 2})
      }
    }

    return {
      busy,
      dataInfo,
      refFormObserver,

      sliderTypeOptions,
      sliderGroupOptions,
      linkTargetOptions,
      linkTypeOptions,
      urlOptions,

      statusOptions,

      onSubmit,
      getValidationState,
      resetForm,
      getApiFile,
      avatarText,
      inputImageRenderer,
      selectFile,
      resetFile,
      getUrlTypeValues,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
